import React, { FC } from 'react';
import { Link } from 'gatsby';
import { Container } from '@design-system/container';
import { HeadingBlock, HeadingBlockProps } from '@design-system/heading-block';
import useSiteTree from 'hooks/useSiteTree';

import { SitemapItem, SitemapListProps } from './models';

import './style.scss';

const SitemapChildren = ({ list }: { list: SitemapItem[] }) =>
  list.length > 0 ? (
    <ul className="sitemap__sublist">
      {list.map((item) => (
        <li key={item.path}>
          <Link to={item.path}>{item.title}</Link>

          <SitemapChildren list={item.children} />
        </li>
      ))}
    </ul>
  ) : null;

const SitemapList: FC<SitemapListProps> = ({ headline_ref, publish_details: { locale } }) => {
  const listOfPages = useSiteTree({ lang: locale });

  return (
    <div className="sitemap">
      <Container maxWidth="max-grid" className="sitemap__list">
        {listOfPages.map((parent) => (
          <div key={parent.path} className="sitemap__item">
            <Link to={parent.path} className="sitemap__title">
              <HeadingBlock
                {...(headline_ref[0].ui as HeadingBlockProps)}
                headline={parent.title}
              />
            </Link>

            <SitemapChildren list={parent.children} />
          </div>
        ))}
      </Container>
    </div>
  );
};

export default SitemapList;
